export enum APP_LANGUAGES {
  ENGLISH = "en",
  SPANISH = "es"
}

export const APP_LANGUAGES_OPTIONS = [
  {
    label: "English",
    value: APP_LANGUAGES.ENGLISH
  },
  {
    label: "Spanish",
    value: APP_LANGUAGES.SPANISH
  }
];

export enum APP_STRINGS_LABELS {
  EDIT_ADDRESS = "EditAddress",
  BACK = "Back",
  NEXT = "Next",
  RESTART_QUIZ = "RestartQuiz",
  CONFIRM_ADDRESS = "ConfirmAddress",
  ENTER_ADDRESS = "EnterAddress",
  ADDRESS_LINE1 = "AddressLine1",
  ADDRESS_LINE2 = "AddressLine2",
  CITY = "City",
  STATE = "State",
  ZIP_CODE = "ZipCode",
  LOOKS_GOOD = "LooksGood",
  UNABLE_FIND_ADDRESS = "UnableFindAddress",
  IS_REQUIRED = "IsRequired",
  FIRST_NAME = "FirstName",
  LAST_NAME = "LastName",
  COMPANY = "Company",
  EMAIL = "Email",
  PHONE_NUMBER = "PhoneNumber",
  TNC_MESSAGE_PART_1 = "TncMessagePart1",
  PRIVACY_POLICY = "PrivacyPolicy",
  AND = "And",
  TERMS_OF_SERVICE = "TermsOfService",
  FOR_MORE_INFORMATION = "ForMoreInformation",
  AGREE_MESSAGE_TEXT = "AgreeMessage",
  Terms = "terms",
  Privacy = "privacy",
  ADDR_REQUIRED_ERR_MSG = "addrRequiredErrMsg",
  ADDR_NOT_FOUND_ERR_MSG = "addrNotFoundErrMsg",
  SOLDAVE_NUMBER = "soldavePhoneNumber",
  SOLDAVE_NUMBER_E164 = "soldavePhoneNumberE164",
  CONTACT_US = "ContactUs",
  QUESTIONS_JUST_ASK = "QuestionsJustAsk",
  QUESTIONS_LET_ME_KNOW = "QuestionsLetMeKnow",
  CALL_ME = "callMe",
  QUESTIONS_CALL_TEXT = "QuestionsCallText",
  TEXT_US = "TextUs",
  QUESTIONS_ANSWERED_FAST = "QuestionsAnsweredFast",
  JUMP_ON_THE_PHONE = "JumpOnThePhone",
  CALL_US = "CallUs",
  QUIZ = "quiz",
  CALL_SOLDAVE = "CallSoldAve",
  PHONE_VERIFY_SEGMENT = "PhoneVerifySegment",
  RESEND_CODE = "ResendCode",
  ISSUE_SEND_CODE_ERR_MSG = "IssueSendCodeErrMsg",
  INVALID_MOBILE_ERR_MSG = "InvalidMobileErrMsg",
  SENDING_CODE = "SendingCode",
  SENT_CODE = "SentCode",
  ENTER_CODE_ERR_MSG = "EnterCodeErrMsg",
  INVALID_CODE_ERR_MSG = "InvalidCodeErrMsg",
  VERIFY_PHONE_NUMBER = "VerifyPhoneNumber",
  ENTER_VERIFICATION_CODE_MSG = "EnterVerificationCodeMsg",
  CODE_SENT_TO_MSG = "CodeSentToMsg",
  ENTER_CODE = "EnterCode",
  SUBMIT = "Submit",
  CODE_SENT_SUCCESS_MSG = "CodeSentSuccessMsg",
  CODE_REQUIRED_MSG = "CodeRequiredMsg",
  PHONE_NUMBER_REQUIRED_MSG = "PhoneNumberRequiredMsg",
  VERIFYING_CODE_MSG = "VerifyingCodeMsg",
  CODE_VERIFIED_MSG = "CodeVerifiedMsg",
  CODE_VERIFY_FAILED_ERR_MSG = "CodeVerifyFailedErrMsg",
  BUILDING_CASH_OFFER_MSG = "BuildingCashOfferMsg",
  LOADING = "Loading",
  REDIRECTING_MSG = "RedirectingMsg"
}

const APP_STRINGS = {
  [APP_LANGUAGES.ENGLISH]: {
    [APP_STRINGS_LABELS.EDIT_ADDRESS]: "Edit Address",
    [APP_STRINGS_LABELS.BACK]: "Back",
    [APP_STRINGS_LABELS.NEXT]: "Next",
    [APP_STRINGS_LABELS.RESTART_QUIZ]: "Restart Quiz",
    [APP_STRINGS_LABELS.CONFIRM_ADDRESS]: "Confirm Your Address",
    [APP_STRINGS_LABELS.ENTER_ADDRESS]: "Enter your address",
    [APP_STRINGS_LABELS.ADDRESS_LINE1]: "Address Line 1",
    [APP_STRINGS_LABELS.ADDRESS_LINE2]: "Address Line 2",
    [APP_STRINGS_LABELS.CITY]: "City",
    [APP_STRINGS_LABELS.STATE]: "State",
    [APP_STRINGS_LABELS.ZIP_CODE]: "Zip Code",
    [APP_STRINGS_LABELS.LOOKS_GOOD]: "Looks Good!",
    [APP_STRINGS_LABELS.UNABLE_FIND_ADDRESS]: "Unable to find address, Click here to add manually",
    [APP_STRINGS_LABELS.IS_REQUIRED]: "is required",
    [APP_STRINGS_LABELS.FIRST_NAME]: "First Name",
    [APP_STRINGS_LABELS.LAST_NAME]: "Last Name",
    [APP_STRINGS_LABELS.COMPANY]: "Company",
    [APP_STRINGS_LABELS.EMAIL]: "Email",
    [APP_STRINGS_LABELS.PHONE_NUMBER]: "Phone Number",
    [APP_STRINGS_LABELS.TNC_MESSAGE_PART_1]:
      "By providing your phone number, you agree to receive text messages from Sold Ave at the number provided. Message and data rates may apply. Reply HELP for help or STOP to cancel at any time. See our",
    [APP_STRINGS_LABELS.PRIVACY_POLICY]: "Privacy Policy",
    [APP_STRINGS_LABELS.AND]: "and",
    [APP_STRINGS_LABELS.TERMS_OF_SERVICE]: "Terms of Service",
    [APP_STRINGS_LABELS.FOR_MORE_INFORMATION]: "for more information",
    [APP_STRINGS_LABELS.AGREE_MESSAGE_TEXT]: "I agree to receive messages from Sold Ave.",
    [APP_STRINGS_LABELS.Terms]: "Terms",
    [APP_STRINGS_LABELS.Privacy]: "Privacy",
    [APP_STRINGS_LABELS.ADDR_REQUIRED_ERR_MSG]: "Address is required",
    [APP_STRINGS_LABELS.ADDR_NOT_FOUND_ERR_MSG]: "Sorry, we couldn't find that address. Please try again.",
    [APP_STRINGS_LABELS.SOLDAVE_NUMBER]: "(855) 954-3990",
    [APP_STRINGS_LABELS.CONTACT_US]: "Contact Us",
    [APP_STRINGS_LABELS.QUESTIONS_JUST_ASK]: "Questions? Just Ask",
    [APP_STRINGS_LABELS.QUESTIONS_LET_ME_KNOW]: "I'm here for you. Let me know how I can help.",
    [APP_STRINGS_LABELS.CALL_ME]: "Call me",
    [APP_STRINGS_LABELS.SOLDAVE_NUMBER_E164]: "+18559543990",
    [APP_STRINGS_LABELS.QUESTIONS_CALL_TEXT]: "Questions? Call or Text",
    [APP_STRINGS_LABELS.TEXT_US]: "Text Us",
    [APP_STRINGS_LABELS.QUESTIONS_ANSWERED_FAST]: "Get your questions answered fast by text.",
    [APP_STRINGS_LABELS.JUMP_ON_THE_PHONE]:
      "Jump on the phone with us between 8:00am and 8:00pm PST any day of the week.",
    [APP_STRINGS_LABELS.CALL_US]: "Call Us",
    [APP_STRINGS_LABELS.QUIZ]: "quiz",
    [APP_STRINGS_LABELS.CALL_SOLDAVE]: "Call Sold Ave",
    [APP_STRINGS_LABELS.PHONE_VERIFY_SEGMENT]: "phone-verify",
    [APP_STRINGS_LABELS.RESEND_CODE]: "Resend code",
    [APP_STRINGS_LABELS.ISSUE_SEND_CODE_ERR_MSG]: "There was an issue sending the code, please try again.",
    [APP_STRINGS_LABELS.INVALID_MOBILE_ERR_MSG]: "Invalid mobile number",
    [APP_STRINGS_LABELS.SENDING_CODE]: "Sending Code...",
    [APP_STRINGS_LABELS.SENT_CODE]: "Sent",
    [APP_STRINGS_LABELS.ENTER_CODE_ERR_MSG]: "Please enter the code",
    [APP_STRINGS_LABELS.INVALID_CODE_ERR_MSG]: "Invalid code, please try again",
    [APP_STRINGS_LABELS.VERIFY_PHONE_NUMBER]: "Ready to See Your Offer?",
    [APP_STRINGS_LABELS.ENTER_VERIFICATION_CODE_MSG]: "Enter the verification code sent to your phone to unlock your personalized offer.",
    [APP_STRINGS_LABELS.CODE_SENT_TO_MSG]: "We sent a code to",
    [APP_STRINGS_LABELS.ENTER_CODE]: "Enter your code",
    [APP_STRINGS_LABELS.SUBMIT]: "Submit",
    [APP_STRINGS_LABELS.CODE_SENT_SUCCESS_MSG]: "Code sent successfully",
    [APP_STRINGS_LABELS.CODE_REQUIRED_MSG]: "Code is required",
    [APP_STRINGS_LABELS.PHONE_NUMBER_REQUIRED_MSG]: "Phone number is required",
    [APP_STRINGS_LABELS.VERIFYING_CODE_MSG]: "Verifying code...",
    [APP_STRINGS_LABELS.CODE_VERIFIED_MSG]: "Code verified successfully!",
    [APP_STRINGS_LABELS.CODE_VERIFY_FAILED_ERR_MSG]: "Failed to verify code. Try again.",
    [APP_STRINGS_LABELS.BUILDING_CASH_OFFER_MSG]: "We are building your cash offer...",
    [APP_STRINGS_LABELS.LOADING]: "Loading",
    [APP_STRINGS_LABELS.REDIRECTING_MSG]: "Redirecting..."
  },
  [APP_LANGUAGES.SPANISH]: {
    [APP_STRINGS_LABELS.EDIT_ADDRESS]: "Editar dirección",
    [APP_STRINGS_LABELS.BACK]: "Atrás",
    [APP_STRINGS_LABELS.NEXT]: "Siguiente",
    [APP_STRINGS_LABELS.RESTART_QUIZ]: "Reiniciar cuestionario",
    [APP_STRINGS_LABELS.CONFIRM_ADDRESS]: "Confirme su dirección",
    [APP_STRINGS_LABELS.ENTER_ADDRESS]: "Ingrese su dirección",
    [APP_STRINGS_LABELS.CITY]: "Ciudad",
    [APP_STRINGS_LABELS.STATE]: "Estado",
    [APP_STRINGS_LABELS.ZIP_CODE]: "Código postal",
    [APP_STRINGS_LABELS.LOOKS_GOOD]: "¡Se ve bien!",
    [APP_STRINGS_LABELS.UNABLE_FIND_ADDRESS]:
      "No se puede encontrar la dirección, haga clic aquí para agregarla manualmente",
    [APP_STRINGS_LABELS.IS_REQUIRED]: "es requerido",
    [APP_STRINGS_LABELS.FIRST_NAME]: "Nombre de pila",
    [APP_STRINGS_LABELS.LAST_NAME]: "Apellido",
    [APP_STRINGS_LABELS.COMPANY]: "Empresa",
    [APP_STRINGS_LABELS.EMAIL]: "Correo electrónico",
    [APP_STRINGS_LABELS.PHONE_NUMBER]: "Número de teléfono",
    [APP_STRINGS_LABELS.TNC_MESSAGE_PART_1]:
      "Al proporcionar su número de teléfono, acepta recibir mensajes de texto de Sold Ave en el número proporcionado. Pueden aplicarse tarifas por mensajes y datos. Responda AYUDA para obtener ayuda o PARE para cancelar en cualquier momento. Consulte nuestra",
    [APP_STRINGS_LABELS.PRIVACY_POLICY]: "Política de privacidad",
    [APP_STRINGS_LABELS.AND]: "y",
    [APP_STRINGS_LABELS.TERMS_OF_SERVICE]: "Términos de servicio",
    [APP_STRINGS_LABELS.FOR_MORE_INFORMATION]: "para obtener más información",
    [APP_STRINGS_LABELS.AGREE_MESSAGE_TEXT]: "Acepto recibir mensajes de Sold Ave.",
    [APP_STRINGS_LABELS.ADDRESS_LINE1]: "Línea de dirección 1",
    [APP_STRINGS_LABELS.ADDRESS_LINE2]: "Línea de dirección 2",
    [APP_STRINGS_LABELS.Terms]: "Términos",
    [APP_STRINGS_LABELS.Privacy]: "Privacidad",
    [APP_STRINGS_LABELS.ADDR_REQUIRED_ERR_MSG]: "La dirección es requerida",
    [APP_STRINGS_LABELS.ADDR_NOT_FOUND_ERR_MSG]:
      "Lo siento, no pudimos encontrar esa dirección. Por favor, inténtelo de nuevo.",
    [APP_STRINGS_LABELS.SOLDAVE_NUMBER]: "(855) 467-6440",
    [APP_STRINGS_LABELS.CONTACT_US]: "Contáctenos",
    [APP_STRINGS_LABELS.QUESTIONS_JUST_ASK]: "¿Preguntas? Solo pregunta",
    [APP_STRINGS_LABELS.QUESTIONS_LET_ME_KNOW]: "Estoy aquí para ti. Hágamelo saber cómo puedo ayudar.",
    [APP_STRINGS_LABELS.CALL_ME]: "Llamame",
    [APP_STRINGS_LABELS.SOLDAVE_NUMBER_E164]: "+18554676440",
    [APP_STRINGS_LABELS.QUESTIONS_CALL_TEXT]: "¿Preguntas? Llama o envía un mensaje de texto",
    [APP_STRINGS_LABELS.TEXT_US]: "Envíanos un mensaje de texto",
    [APP_STRINGS_LABELS.QUESTIONS_ANSWERED_FAST]: "Obtenga respuestas rápidas a sus preguntas por mensaje de texto.",
    [APP_STRINGS_LABELS.JUMP_ON_THE_PHONE]:
      "Salta al teléfono con nosotros entre las 8:00 a. m. y las 8:00 p. m. PST cualquier día de la semana.",
    [APP_STRINGS_LABELS.CALL_US]: "Llámanos",
    [APP_STRINGS_LABELS.QUIZ]: "cuestionario",
    [APP_STRINGS_LABELS.CALL_SOLDAVE]: "Llame a Sold Ave",
    [APP_STRINGS_LABELS.PHONE_VERIFY_SEGMENT]: "verificar-telefono",
    [APP_STRINGS_LABELS.RESEND_CODE]: "Reenviar código",
    [APP_STRINGS_LABELS.ISSUE_SEND_CODE_ERR_MSG]: "Hubo un problema al enviar el código, por favor inténtalo.",
    [APP_STRINGS_LABELS.INVALID_MOBILE_ERR_MSG]: "Número de móvil no válido",
    [APP_STRINGS_LABELS.SENT_CODE]: "Enviado",
    [APP_STRINGS_LABELS.SENDING_CODE]: "Enviando código...",
    [APP_STRINGS_LABELS.ENTER_CODE_ERR_MSG]: "Por favor, introduzca el código",
    [APP_STRINGS_LABELS.INVALID_CODE_ERR_MSG]: "Código no válido, por favor inténtelo de nuevo",
    [APP_STRINGS_LABELS.VERIFY_PHONE_NUMBER]: "¿Listo para Ver tu Oferta?",
    [APP_STRINGS_LABELS.ENTER_VERIFICATION_CODE_MSG]:
      "Ingresa el código de verificación enviado a tu teléfono para desbloquear tu oferta personalizada.",
    [APP_STRINGS_LABELS.CODE_SENT_TO_MSG]: "Enviamos un código a",
    [APP_STRINGS_LABELS.ENTER_CODE]: "Ingrese su código",
    [APP_STRINGS_LABELS.SUBMIT]: "Enviar",
    [APP_STRINGS_LABELS.CODE_SENT_SUCCESS_MSG]: "Código enviado con éxito",
    [APP_STRINGS_LABELS.CODE_REQUIRED_MSG]: "El código es requerido",
    [APP_STRINGS_LABELS.PHONE_NUMBER_REQUIRED_MSG]: "El número de teléfono es requerido",
    [APP_STRINGS_LABELS.VERIFYING_CODE_MSG]: "Verificando código...",
    [APP_STRINGS_LABELS.CODE_VERIFIED_MSG]: "Código verificado con éxito",
    [APP_STRINGS_LABELS.CODE_VERIFY_FAILED_ERR_MSG]: "Error al verificar el código. Inténtalo de nuevo",
    [APP_STRINGS_LABELS.LOADING]: "Cargando",
    [APP_STRINGS_LABELS.BUILDING_CASH_OFFER_MSG]: "Estamos construyendo tu oferta en efectivo...",
    [APP_STRINGS_LABELS.REDIRECTING_MSG]: "Redirigiendo..."
  }
};

export const getAppStringForLanguage = (label: APP_STRINGS_LABELS, language = APP_LANGUAGES.ENGLISH) => {
  return APP_STRINGS[language][label] || APP_STRINGS[APP_LANGUAGES.ENGLISH][label];
};
