"use client";

import React, { createContext, useCallback, useState } from "react";
import { RecordItem } from "types/common";
import { ESCAPE_EVENT_RESOLVED_BY_COMP } from "utils/constants";
import { APP_LANGUAGES } from "utils/language";
import toast from "utils/toast";

export type MENU_POSITION = "left" | "right" | "top";
export type UiState = {
  menuDirection?: "vertical" | "horizontal";
  isCollapsed?: boolean;
  menuPosition?: MENU_POSITION;
  disabledDefaultWebsiteHeaderFooter?: boolean;
  websiteTileLanguage?: APP_LANGUAGES;
};
export interface UIContextState {
  uiState?: UiState;
  updateUIState: (newPropValues: Partial<UiState>) => void;
  resetPageState: () => void;
  childBackPagePath: string | undefined; // When we redirect to parent detail page, go back uses this property
  updateChildBackPagePath: (path?: string) => void;
  escapeEventResolvedByComp?: ESCAPE_EVENT_RESOLVED_BY_COMP;
  setEscapeEventResolvedByComp: (comp?: ESCAPE_EVENT_RESOLVED_BY_COMP) => void;
  showErrorToast: (errorMessage: string, options?: RecordItem) => void;
}

export const UIContext = createContext<UIContextState | null>(null);

const { Provider } = UIContext;

export const UIContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [uiState, setUIState] = useState<UiState>({
    isCollapsed: true,
    disabledDefaultWebsiteHeaderFooter: true
  });
  const [childBackPagePath, setChildBackPagePath] = useState<string | undefined>();
  const [escapeEventResolvedByComp, setEscapeEventResolvedByComp] = useState<
    ESCAPE_EVENT_RESOLVED_BY_COMP | undefined
  >();

  const updateUIState = useCallback((newPropValues: Partial<UiState>) => {
    setUIState((prev) => {
      return {
        ...prev,
        ...newPropValues
      };
    });
  }, []);

  const resetPageState = useCallback(() => {
    setUIState({
      menuDirection: "vertical"
    });
  }, []);

  const updateChildBackPagePath = useCallback((path?: string) => {
    setChildBackPagePath(path);
  }, []);

  // This method will handle error display, currently set to use toast,
  //  but can be updated to use a different component if needed
  const showErrorToast = useCallback((errorMessage: string, options?: RecordItem) => {
    if (!errorMessage) return;
    toast.error(errorMessage, {
      autoClose: 10000,
      ...options
    });
  }, []);

  return (
    <Provider
      value={{
        uiState,
        updateUIState,
        resetPageState,
        childBackPagePath,
        updateChildBackPagePath,
        escapeEventResolvedByComp,
        setEscapeEventResolvedByComp,
        showErrorToast
      }}
    >
      {children}
    </Provider>
  );
};
